.applicationSubmittedContainer {
  border-radius: 8px;
  border: 1px solid var(--Grey-Grey25, #f3f4f6);
  background: var(--Grey-Grey0, #fff);
  box-shadow: 0px 2px 4px 0px #eee;
  display: flex;
  width: 456px;
  padding: 42.5px 32px 33.5px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}
.applicationSubmittedContainer img {
  height: 165px;
}
.applicationSubmittedContainer > p:nth-child(1) {
  color: var(--Grey-Grey80, #454b5f);
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  margin: 0px;
}

.applicationSubmittedFooter p:nth-child(2) {
  color: var(--Grey-Grey80, #454b5f);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align-last: center;
  margin: 0px;
}
.applicationSubmittedEvaluation {
  color: var(--Grey-Grey80, #454b5f);
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align-last: center;
  margin: 0px;
}
.applicationSubmittedFooter span {
  color: var(--Grey-Grey80, #454b5f);
  text-align: center;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  align-items: center;
  gap: 6px;
}

.applicationSubmittedFooter span p {
  color: var(--Primary-Blue60, #355fd4);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0px;
}

@media screen and (max-width: 470px) {
  .applicationSubmittedContainer > p:nth-child(1) {
    font-size: 1.3em;
  }
  .applicationSubmittedFooter > span:nth-child(1) {
    font-size: 19px;
  }
}
