.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.leftContainer {
  width: 50vw;
  height: 100vh;
  background: linear-gradient(
    233deg,
    #132557 -9.49%,
    #020212 64.74%,
    #132557 132.16%
  );
  padding: 32px 0 22px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.rightContainer {
  width: 50vw;
  height: 100vh;
}
.bannerContent {
  display: flex;
  flex-direction: column;
}
.bannerContent p {
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
}
.bannerContent span {
  color: #fff;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.bannerContentHeading {
  font-size: 38px !important;
  font-weight: 700 !important;
}
.bannerContentSubHeading {
  color: #909090 !important;
  font-size: 16px !important;
  padding: 2px 0 48px 0;
  width: 70%;
}
.footerContent p {
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--Grey-Grey30, #d0d3dd);
  margin: 0px;
}
.footerContent span {
  display: flex;
  flex-direction: row;
  gap: 4px;
  color: var(--Grey-Grey30, #d0d3dd);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.footerContentSubHeading {
  color: var(--Grey-Grey30, #d0d3dd);
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.bannerContentDesc {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.mainContainer {
  position: relative;
}

@media screen and (max-width: 670px) {
  .leftContainer {
    display: none;
  }
  .rightContainer {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .rightContainer {
    width: 100%;
  }
}
