.container {
  background-color: black;
  display: flex;
  height: 100vh;
}
.welcomeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  padding: 1rem;
}
.welcomeContainer p {
  font-size: 48px;
  font-weight: 700;
}
.canvasContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.canvasOverlapContainer img {
  position: absolute;
}

.heading {
  color: white;
}

.subHeading {
  color: grey;
}

@media screen and (max-width: 670px) {
  .heading {
    font-size: 2rem !important;
  }

  .subHeading {
    font-size: 1.5rem !important;
  }
}
