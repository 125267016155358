.formInputsContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 32px 0 56px 0px;
    row-gap: 32px;
  }
  
  .formContainer p {
    color: var(--grey-grey-100-base, #1a1c23);
    font-family: "DM Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 4px;
    margin-top: 0px;
  }
  .formContainer > p:nth-child(1) {
    color: var(--Grey-Grey80, #454b5f);
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    align-self: stretch;
  }
  .formContainer > p:nth-child(2) {
    color: var(--Grey-Grey40, #959bb2);
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    align-self: stretch;
    margin-top: 0px;
  }
  @media (max-width: 960px) {
    .formContainer p {
      font-size: 12px;
    }
  }