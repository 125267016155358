.buttonContainer {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    width: 100%;
    border-radius: 4px;
    background: var(--Primary-Blue110, #193276);
    color: var(--Grey-Grey0, #FFF);
    text-align: center;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}