.formContainer {
  margin-top: -2rem;
}
.formInputsContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 32px 0 56px 0px;
  row-gap: 32px;
}

.formContainer p {
  color: var(--grey-grey-100-base, #1a1c23);
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 4px;
  margin-top: 0px;
}
@media (max-width: 960px) {
  .formContainer p {
    font-size: 12px;
  }
}
@media (max-width: 800px) and (min-width: 678px) {
  .formInputsContainer {
    flex-direction: column;
  }
}

@media screen and (max-width: 470px) {
  .formInputsContainer {
    flex-direction: column;
    gap: 1em;
  }
}
