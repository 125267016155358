.formContainer {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
  height: 100vh;
}

.formContainer input {
  border-radius: 2px;
  border-radius: 2px;
  border-top: 1px solid var(--Grey-Grey30, #d0d3dd);
  border-right: 1px solid var(--Grey-Grey30, #d0d3dd);
  border-bottom: 2px solid var(--Grey-Grey30, #d0d3dd);
  border-left: 1px solid var(--Grey-Grey30, #d0d3dd);
  display: flex;
  padding: 12px 16px;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 98%;
}
.formContainer input ::placeholder {
  color: var(--Grey-Grey50, #8990a9);
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.ApllicationSubmiiterFormContainer {
  height: 100vh;
  /* text-align: -webkit-center; */
  display: flex;
  align-items: center;
  justify-content: center;
}
