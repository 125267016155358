.smallBannerContainer {
  position: fixed;
  z-index: 999999;
  bottom: 0;
  right: 0;
  border-radius: 4px;
  padding-right: 5px;
  padding-bottom: 4px;
  width: 190px;
  height: 146px;
}

.smallBannerContainer img {
  border-radius: 4px;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  .smallBannerContainer {
    width: 140px;
    height: 106px;
  }
}

@media screen and (max-width: 470px) {
  .smallBannerContainer {
    width: 150px;
    height: 116px;
  }
}
