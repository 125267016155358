@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.container {
  background-color: black;
  display: flex;
  height: 100vh;
}
.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
}
.welcome-container p {
  font-size: 48px;
  font-weight: 700;

}
.canvas-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.canvas-overlap-container img{
position: absolute;
}